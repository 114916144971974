import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ThreeScene from '../components/ThreeScene';
import styles from './ResultsPage.module.css';
import { getResultsData } from '../services/api';

interface ResultItem {
  brain_regions: string[];
  category_id: string;
  score: number;
  description: string;
}

interface LocationState {
  uuid: string;
  rehabId: string;
}

const getColorForScore = (score: number): string => {
  // Convert score to 0-1 range
  const t = score / 100;
  
  // Create soft, readable colors that work well on dark background
  const colors = {
    red: 'rgba(255, 89, 89, 0.9)',     // Soft red for low scores
    orange: 'rgba(255, 170, 85, 0.9)',  // Soft orange for middle scores
    green: 'rgba(92, 184, 92, 0.9)'     // Soft green for high scores
  };
  
  if (t <= 0.5) {
    // Interpolate between red and orange
    return t * 2 === 0 ? colors.red : `color-mix(in srgb, ${colors.red}, ${colors.orange} ${t * 200}%)`;
  } else {
    // Interpolate between orange and green
    return `color-mix(in srgb, ${colors.orange}, ${colors.green} ${(t - 0.5) * 200}%)`;
  }
};

// Helper function to capitalize first letter of each word
const toTitleCase = (str: string): string => {
  return str
    .toLowerCase()
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const ResultsPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [results, setResults] = useState<ResultItem[]>([]);
  const [titleAnimated, setTitleAnimated] = useState(false);
  const [brainAssembled, setBrainAssembled] = useState(false);
  const [brainMoved, setBrainMoved] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const location = useLocation();
  const [activeCategory, setActiveCategory] = useState<string | null>(null);
  const [resultsVisible, setResultsVisible] = useState<number>(-1);

  const handleCategoryClick = (categoryId: string) => {
    setActiveCategory(prev => prev === categoryId ? null : categoryId);
  };

  const handleContainerClick = (e: React.MouseEvent) => {
    // Only handle clicks directly on the container, not its children
    if (e.target === e.currentTarget) {
      setActiveCategory(null);
    }
  };

  useEffect(() => {
    const fetchResults = async () => {
      try {
        if (!location.state) {
          setError('No state provided - please return to the overview page');
          setIsLoading(false);
          return;
        }

        const { uuid, rehabId } = location.state as LocationState;
        
        if (!uuid || !rehabId) {
          console.error('Missing required data:', { uuid, rehabId });
          setError('Missing required data - please return to the overview page');
          setIsLoading(false);
          return;
        }

        const resultsData = await getResultsData(uuid, rehabId);
        if (!resultsData?.data || !Array.isArray(resultsData.data)) {
          console.error('Results data is not in the expected format:', resultsData);
          setError('Invalid results format received');
          return;
        }
        setResults(resultsData.data);
        setIsLoading(false);
      } catch (err) {
        console.error('Error fetching results:', err);
        setError('Failed to fetch results');
        setIsLoading(false);
      }
    };

    fetchResults();
  }, []);

  useEffect(() => {
    if (isLoading || results.length === 0) return;

    // Animation sequence timing
    const brainAssemblyTime = 2000;    
    const brainMoveTime = 1000;        
    const brainTransitionTime = 1000;  
    const titleDelay = 500;            
    const resultStaggerDelay = 100;    // Reduced delay between results

    // Sequence the animations
    setTimeout(() => setBrainAssembled(true), brainAssemblyTime);
    setTimeout(() => setBrainMoved(true), brainAssemblyTime + brainMoveTime);
    setTimeout(() => setTitleAnimated(true), brainAssemblyTime + brainMoveTime + titleDelay);
    
    const totalBrainTime = brainAssemblyTime + brainMoveTime + brainTransitionTime;
    
    // Start showing results after brain has moved
    const resultAnimation = setTimeout(() => {
      setResultsVisible(0);
      
      const resultInterval = setInterval(() => {
        setResultsVisible(prev => {
          if (prev >= results.length - 1) {
            clearInterval(resultInterval);
            return prev;
          }
          return prev + 1;
        });
      }, resultStaggerDelay);

      return () => clearInterval(resultInterval);
    }, totalBrainTime);

    return () => clearTimeout(resultAnimation);
  }, [isLoading, results]);

  if (isLoading) {
    return <div className={styles.loadingContainer}></div>;
  }

  if (error) {
    return <div className={styles.errorContainer}>{error}</div>;
  }

  return (
    <div className={styles.container}>
      <div className={`${styles.visualizationContainer} ${brainMoved ? styles.brainMoved : ''}`}>
        <ThreeScene 
          isAssembled={brainAssembled} 
          results={results}
          activeCategory={activeCategory}
          onRegionClick={handleCategoryClick}
        />
      </div>
      
      <h1 className={`${styles.title} ${titleAnimated ? styles.titleAnimated : ''}`}>
        my results
      </h1>

      <div 
        className={styles.resultsContainer}
        onClick={handleContainerClick}
      >
        {results.map((result, index) => (
          <div 
            key={result.category_id} 
            className={`
              ${styles.resultRow} 
              ${index <= resultsVisible ? styles.show : ''} 
              ${activeCategory === result.category_id ? styles.active : ''}
              ${activeCategory && activeCategory !== result.category_id ? styles.faded : ''}
            `}
            onClick={(e) => {
              e.stopPropagation(); // Prevent container click from firing
              handleCategoryClick(result.category_id);
            }}
          >
            <div className={styles.barContainer}>
              <div 
                className={styles.barFill} 
                style={{ 
                  width: `${result.score}%`,
                  backgroundColor: getColorForScore(result.score)
                }}
              />
            </div>
            <div className={styles.barLabels}>
              <span 
                className={styles.categoryLabel}
                style={{ color: getColorForScore(result.score) }}
              >
                {toTitleCase(result.category_id)}
              </span>
              <span 
                className={styles.scoreLabel}
                style={{ color: getColorForScore(result.score) }}
              >
                <strong>{result.score}</strong>%
              </span>
            </div>
            <div className={styles.description}>
              {result.description}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ResultsPage; 
