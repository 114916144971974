import React, { useState } from 'react';
import { Exercise } from '../types';
import styles from './NonRehabExercises.module.css';
import TagInfoWindow from './TagInfoWindow';

interface NonRehabExercisesProps {
  exercises: Exercise[] | undefined;
  firstName: string;
}

const NonRehabExercises: React.FC<NonRehabExercisesProps> = ({ exercises, firstName }) => {
  const [selectedExercise, setSelectedExercise] = useState<Exercise | null>(null);

  const handleTagClick = (exercise: Exercise) => {
    setSelectedExercise(exercise);
  };

  const handleCloseTagInfo = () => {
    setSelectedExercise(null);
  };

  if (!exercises || exercises.length === 0) {
    return null; // or return some placeholder content
  }

  return (
    <div className={styles.container}>
      <h2 className={styles.sectionTitle}>
        {firstName ? `${firstName}, here's our gameplan` : 'The gameplan'}
      </h2>
      <div className={styles.tagContainer}>
        {exercises.map((exercise) => (
          <span
            key={exercise.id || `non-rehab-${exercise.title}`}
            className={styles.tag}
            onClick={() => handleTagClick(exercise)}
          >
            {exercise.title}
          </span>
        ))}
      </div>
      {selectedExercise && (
        <TagInfoWindow
          title={selectedExercise.title}
          description={selectedExercise.description}
          onClose={handleCloseTagInfo}
        />
      )}
    </div>
  );
};

export default NonRehabExercises;
