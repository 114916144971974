import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Overview from './pages/Overview';
import SplashScreen from './components/SplashScreen';
import SurveyPage from './pages/SurveyPage';
import VideoViewPage from './pages/VideoViewPage';
import { getPatientData, setCompletionStatus } from './services/api';
import { Exercise } from './types';
import './App.css';
import NonRehabExercises from './components/NonRehabExercises';
import ResultsPage from './pages/ResultsPage';

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [rehabExercises, setRehabExercises] = useState<Exercise[]>([]);
  const [nonRehabExercises, setNonRehabExercises] = useState<Exercise[]>([]);
  const [firstName, setFirstName] = useState<string>('');
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getPatientData();
        setRehabExercises(data.data.rehabArray || []);
        setNonRehabExercises(data.data.nonRehabArray || []);
        setFirstName(data.data.first_name || '');
        setIsLoading(false);
      } catch (err) {
        setError('Failed to fetch patient data');
        setRehabExercises([]);
        setNonRehabExercises([]);
        setFirstName('');
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <Router>
      <div className="App">
        {isLoading ? (
          <SplashScreen />
        ) : (
          <Routes>
            <Route path="/" element={
              <Overview 
                rehabExercises={rehabExercises} 
                nonRehabExercises={nonRehabExercises}
                firstName={firstName}
                error={error}
              />
            } />
            <Route 
              path="/survey" 
              element={
                <SurveyPage 
                  onAction={(action, content, patientId, rehabId) => {
                    // Call the same function that Overview uses
                    setRehabExercises(prev => 
                      prev.map(exercise => 
                        exercise.rehabId === rehabId
                          ? { ...exercise, action, content }
                          : exercise
                      )
                    );
                    // Call the API
                    setCompletionStatus(action, content, patientId, rehabId);
                  }} 
                />
              } 
            />
            <Route path="/video-view" element={
              <VideoViewPage 
                onAction={(action, content, patientId, rehabId) => {
                  // Update local state
                  setRehabExercises(prev => 
                    prev.map(exercise => 
                      exercise.rehabId === rehabId
                        ? { ...exercise, action, content }
                        : exercise
                    )
                  );
                  // Call the API
                  setCompletionStatus(action, content, patientId, rehabId);
                }} 
              />
            } />
            <Route 
              path="/results" 
              element={<ResultsPage />} 
            />
          </Routes>
        )}
      </div>
    </Router>
  );
}

export default App;
