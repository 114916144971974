import React from 'react';
import styles from './TagInfoWindow.module.css';

interface TagInfoWindowProps {
  title: string;
  description: string;
  onClose: () => void;
  customTitle?: string;
}

const TagInfoWindow: React.FC<TagInfoWindowProps> = ({ title, description, onClose, customTitle }) => {
  return (
    <div className={styles.overlay} onClick={onClose}>
      <div className={styles.window} onClick={(e) => e.stopPropagation()}>
        <button className={styles.closeButton} onClick={onClose}>×</button>
        <h3 className={styles.title}>{customTitle || title}</h3>
        <pre className={styles.description}>{description}</pre>
      </div>
    </div>
  );
};

export default TagInfoWindow;
