import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form } from "@quillforms/renderer-core";
import "@quillforms/renderer-core/build-style/style.css";
import "./custom-opinion-block";
// @ts-ignore
import { registerCoreBlocks } from "@quillforms/react-renderer-utils";

registerCoreBlocks();

interface SurveyState {
  surveyQuestions: any[];
  exerciseId: string;
  patientId: string;
}

interface SurveyPageProps {
  onAction: (
    action: 'completed' | 'incomplete',
    content: { comments?: string; survey_answers?: any } | {},
    patientId: string,
    rehabId: string
  ) => void;
}

const SurveyPage: React.FC<SurveyPageProps> = ({ onAction }) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    console.log("Location state:", location.state);
  }, [location.state]);

  // Clear any existing form data when component mounts
  useEffect(() => {
    // Clear QuillForms storage for this form
    if (typeof window !== 'undefined') {
      localStorage.removeItem('QF_formData_1');
      localStorage.removeItem('QF_answers_1');
      localStorage.removeItem('QF_current_block_1');
    }
  }, []); // Changed to empty dependency array since we'll handle exerciseId below

  if (!location.state) {
    console.error("No state found in location");
    return <div>Error: No survey data available. Please go back and try again.</div>;
  }

  const { surveyQuestions, exerciseId } = location.state as SurveyState;

  console.log("Survey Questions:", surveyQuestions);
  console.log("Exercise ID:", exerciseId);

  if (!surveyQuestions || surveyQuestions.length === 0) {
    console.error("No survey questions found");
    return <div>Error: No survey questions available. Please go back and try again.</div>;
  }

  const submitSurveyData = (id: string, data: any) => {
    // Define interface for answers
    const relevantAnswers: { [key: string]: any } = {};
    const currentQuestionIds = surveyQuestions.map(q => q.id);
    
    Object.entries(data.answers || {}).forEach(([key, value]) => {
      if (currentQuestionIds.includes(key)) {
        relevantAnswers[key] = value;
      }
    });

    console.log("Filtered survey answers:", relevantAnswers);
    
    const { patientId } = location.state as SurveyState & { patientId: string };
    onAction('completed', { survey_answers: { answers: relevantAnswers } }, patientId, id);
  };

  return (
    <div style={{ width: "100%", height: "100vh"}}>
      <Form
        key={exerciseId} // Ensure new instance on exerciseId change
        formId={1}
        applyLogic={false}
        formObj={{
          blocks: surveyQuestions,
          customCSS: `
              // majority of background and text color
              div {
                  background-color: #000000; /* Dark background */
                  color: #ffffff; /* Light text color */
              }

              // text "OK"
              div.renderer-core-html-parser {
                  background-color: rgba(0, 0, 0, 0); /* Dark background with 0 opacity */
                  color: #ffffff; /* Light text color */
              }

              // question arrow
              .css-who0lh { 
                  stroke: #ffffff; 
              }

              // input {
              //     background-color: #333333;
              //     color: #1e1e1e;
              //     border: 1px solid #555555;
              // }

              // input::placeholder {
              //     color: #aaaaaa;
              // }

              // button {
              //     background-color: #444444;
              //     color: #ffffff;
              //     border: 1px solid #666666;
              // }

              // button:hover {
              //     background-color: #555555;
              // }

              // label {
              //     color: #ffffff;
              // }

              // progress-bar {
              //     background-color: #ffffff;
              //     color: #ffffff;
              // }

              // option-button {
              //     background-color: #333333;
              //     color: #ffffff;
              //     border: 1px solid #555555;
              // }

              // option-button:hover {
              //     background-color: #444444;
              // }
          `
        }}
        onSubmit={(data: any, { completeForm, setIsSubmitting }: any) => {
          submitSurveyData(exerciseId, data);
          setTimeout(() => {
            setIsSubmitting(false);
            completeForm();
            // Clear form data again before navigating
            if (typeof window !== 'undefined') {
              localStorage.removeItem('QF_formData_1');
              localStorage.removeItem('QF_answers_1');
              localStorage.removeItem('QF_current_block_1');
            }
            navigate(-1);
          }, 500);
        }}
      />
    </div>
  );
};

export default SurveyPage;
