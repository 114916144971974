import axios from 'axios';

const GET_PATIENT_DATA_URL = process.env.REACT_APP_GET_PATIENT_DATA_URL || 'https://get-patient-data-4qhrk6wppq-uc.a.run.app';
const SET_COMPLETION_STATUS_URL = process.env.REACT_APP_SET_COMPLETION_STATUS_URL || 'https://set-completion-status-4qhrk6wppq-uc.a.run.app';
const RESET_EXERCISE_STATUS_URL = process.env.REACT_APP_RESET_EXERCISE_STATUS_URL || 'https://reset-exercise-status-4qhrk6wppq-uc.a.run.app';
const GET_RESULTS_DATA_URL = process.env.REACT_APP_GET_RESULTS_DATA_URL || 'https://get-results-data-4qhrk6wppq-uc.a.run.app';

const apiClient = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true, // Add this line
});

export const getPatientData = async () => {
  try {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const urlParams = new URLSearchParams(window.location.search);
    const uuid = urlParams.get('uuid') || "null"; // Use default if not provided 2b8823f8-12b7-45a2-bb9c-c69be4065356
    const response = await apiClient.post(GET_PATIENT_DATA_URL, {
      uuid: uuid,
      timezone: userTimezone
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching patient data:', error);
    throw error;
  }
};

export const setCompletionStatus = async (
  action: string,
  content: Record<string, any>,
  patientId: string,
  addedRehabId: string
) => {
  try {
    console.log('Content being sent:', content); // Added console.log
    const response = await apiClient.post(SET_COMPLETION_STATUS_URL, {
      action,
      content,
      patient_id: patientId,
      added_rehab_id: addedRehabId,
    });
    return response.data;
  } catch (error) {
    console.error('Error setting completion status:', error);
    throw error;
  }
};

export const resetExerciseStatus = async (
  uuid: string,
  addedRehabId: string
) => {
  try {
    const response = await apiClient.post(RESET_EXERCISE_STATUS_URL, {
      uuid: uuid,
      added_rehab_id: addedRehabId,
    });
    return response.data;
  } catch (error) {
    console.error('Error resetting exercise status:', error);
    throw error;
  }
};

export const getResultsData = async (uuid: string, rehabId: string) => {
  try {
    const response = await apiClient.post(GET_RESULTS_DATA_URL, {
      uuid: uuid,
      rehab_id: rehabId
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching results data:', error);
    throw error;
  }
};
