import React, { useState, useEffect } from 'react';
import { setCompletionStatus, resetExerciseStatus } from '../services/api';
import ExerciseCard from '../components/ExerciseCard';
import NonRehabExercises from '../components/NonRehabExercises';
import AudioPlayer from '../components/AudioPlayer';
import styles from './Overview.module.css';
import { Exercise } from '../types';
import { useNavigate } from 'react-router-dom';

interface OverviewProps {
  rehabExercises: Exercise[];
  nonRehabExercises: Exercise[];
  firstName: string;
  error: string | null;
}

const Overview: React.FC<OverviewProps> = ({ rehabExercises, nonRehabExercises, firstName, error }) => {
  const [exercises, setExercises] = useState<Exercise[]>([]);
  const navigate = useNavigate();

  useEffect(() => {

    const mappedExercises = rehabExercises.map(exercise => ({
      ...exercise,
      action: exercise.action || 'assigned',
      content: exercise.content || {}
    }));

    setExercises(mappedExercises);
  }, [rehabExercises, nonRehabExercises, error]);

  console.log('Exercises state:', exercises);

  const handleAction = async (
    action: 'completed' | 'incomplete',
    content: { comments?: string } | {},
    patientId: string,
    rehabId: string
  ) => {
    // Optimistic UI update
    setExercises((prev) =>
      prev.map((exercise) =>
        exercise.rehabId === rehabId
          ? {
              ...exercise,
              action,
              content: content || {},
            }
          : exercise
      )
    );

    try {
      await setCompletionStatus(action, content, patientId, rehabId);
    } catch (err) {
      // Revert UI update on error
      setExercises((prev) =>
        prev.map((exercise) =>
          exercise.rehabId === rehabId
            ? { ...exercise, action: 'assigned', content: {} }
            : exercise
        )
      );
      console.error('Error updating exercise status:', err);
    }
  };

  const handleReset = async (rehabId: string) => {
    // Optimistic UI update
    setExercises((prev) =>
      prev.map((exercise) =>
        exercise.rehabId === rehabId
          ? {
              ...exercise,
              action: 'assigned',
              content: {},
            }
          : exercise
      )
    );

    try {
      const urlParams = new URLSearchParams(window.location.search);
      const uuid = urlParams.get('uuid') || 'null';
      await resetExerciseStatus(uuid, rehabId);
    } catch (err) {
      // Revert UI update on error
      setExercises((prev) =>
        prev.map((exercise) =>
          exercise.rehabId === rehabId
            ? { ...exercise }
            : exercise
        )
      );
      console.error('Error resetting exercise:', err);
    }
  };

  const handleViewResults = (rehabId: string) => {
    const urlParams = new URLSearchParams(window.location.search);
    const uuid = urlParams.get('uuid') || 'null';
    
    console.log('Navigating to results with:', { uuid, rehabId });
    navigate('/results', {
      state: {
        uuid,
        rehabId
      },
      replace: false
    });
  };

  if (error) return <div>{error}</div>;

  return (
    <div>
      {/* <AudioPlayer /> */}
      <NonRehabExercises 
        exercises={nonRehabExercises} 
        firstName={firstName} 
      />
      <h2 className={styles.sectionTitle}>Today's drills 💪</h2>
      {exercises.length > 0 ? (
        exercises.map((exercise) => (
          <ExerciseCard 
            key={exercise.rehabId} 
            exercise={exercise} 
            onAction={handleAction} 
            onReset={handleReset} 
            onViewResults={handleViewResults} 
          />
        ))
      ) : (
        <div className={styles.emptyStateContainer}>
          <p className={styles.emptyStateText}>
              All caught up! <br></br>Time to celebrate your progress 🎉
          </p>
        </div>
      )}
    </div>
  );
};

export default Overview;
