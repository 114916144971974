import React, { useEffect, useRef, useState, useCallback } from 'react';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { InstancedUniformsMesh } from 'three-instanced-uniforms-mesh';
import { ShaderMaterial } from 'three';
import { gsap } from 'gsap';
import { Mesh, BufferGeometry } from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

// Camera constants
const CAMERA_BASE_POSITION = new THREE.Vector3(0, 0, 1.2);
const CAMERA_MOVE_RANGE = 0.1;
const CAMERA_LERP_SPEED = 0.05;
const MOBILE_BREAKPOINT = 768;
const MOBILE_CAMERA_POSITION = new THREE.Vector3(0, 0, 1.8); // Further back for mobile
const DESKTOP_CAMERA_POSITION = new THREE.Vector3(0, 0, 1.2); // Original position
const MOBILE_FOV = 120; // Wider FOV for mobile
const DESKTOP_FOV = 75; // Original FOV

const SCATTER_RANGE = 2.0; // How far the points scatter
const ANIMATION_DURATION = 2.5; // Duration in seconds

const isFrontalLobe = (x: number, y: number, z: number): boolean => {
  // Convert coordinates to a 0-1 range for easier calculation
  const normalizedZ = (z + 0.5) / 1.0;
  const normalizedY = (y + 0.5) / 1.0;
  
  // Central sulcus is approximately at 30% from the front
  const centralSulcusZ = 0.3;
  
  // Lateral sulcus angle (approximately 45 degrees)
  const lateralSulcusSlope = 1.0;
  const lateralSulcusOffset = 0.2;
  
  // Make the detection more restrictive
  return (
    normalizedZ > centralSulcusZ && // Forward of central sulcus
    normalizedY > (lateralSulcusSlope * normalizedZ + lateralSulcusOffset) && // Above lateral sulcus
    Math.abs(x) < 0.2 && // Reduced from 0.4 to 0.2 for narrower width
    z < 0.15 // Add limit to front extent
  );
};

// Update the helper function to work with ArrayLike<number> and return bounds info
interface BrainBounds {
  center: THREE.Vector3;
  min: THREE.Vector3;
  max: THREE.Vector3;
}

const analyzeBrainGeometry = (positions: ArrayLike<number>): BrainBounds => {
  let minX = Infinity, maxX = -Infinity;
  let minY = Infinity, maxY = -Infinity;
  let minZ = Infinity, maxZ = -Infinity;

  for (let i = 0; i < positions.length; i += 3) {
    minX = Math.min(minX, positions[i]);
    maxX = Math.max(maxX, positions[i]);
    minY = Math.min(minY, positions[i + 1]);
    maxY = Math.max(maxY, positions[i + 1]);
    minZ = Math.min(minZ, positions[i + 2]);
    maxZ = Math.max(maxZ, positions[i + 2]);
  }

  return {
    center: new THREE.Vector3(
      (minX + maxX) / 2,
      (minY + maxY) / 2,
      (minZ + maxZ) / 2
    ),
    min: new THREE.Vector3(minX, minY, minZ),
    max: new THREE.Vector3(maxX, maxY, maxZ)
  };
};

interface ResultItem {
  brain_regions: string[];
  category_id: string;
  score: number;
}

interface BrainRegion {
  name: string;
  score: number;
  center: THREE.Vector3;
}

interface ThreeSceneProps {
  isAssembled: boolean;
  results: ResultItem[];
  activeCategory: string | null;
  onRegionClick?: (category: string) => void;
}

const ThreeScene: React.FC<ThreeSceneProps> = ({ isAssembled, results, activeCategory, onRegionClick }) => {
  const mountRef = useRef<HTMLDivElement>(null);
  const sceneRef = useRef<THREE.Scene>();
  const cameraRef = useRef<THREE.PerspectiveCamera>();
  const rendererRef = useRef<THREE.WebGLRenderer>();
  const brainRef = useRef<THREE.Object3D>();
  const instancedMeshRef = useRef<InstancedUniformsMesh<ShaderMaterial>>();
  const [hover, setHover] = useState(false);
  const raycasterRef = useRef<THREE.Raycaster>(new THREE.Raycaster());
  const mouseRef = useRef<THREE.Vector2>(new THREE.Vector2());
  const planeRef = useRef<THREE.Mesh>();
  const targetCameraPositionRef = useRef(new THREE.Vector3(0, 0, 1.2));
  const lastInteractionTimeRef = useRef<number>(Date.now());
  const autoRotationGroupRef = useRef<THREE.Group>();
  const overlayRef = useRef<HTMLCanvasElement>(null);
  const ctx = useRef<CanvasRenderingContext2D | null>(null);
  const labelPositionsRef = useRef<Map<string, { 
    x: number; 
    y: number; 
    side: 'left' | 'right' | 'top' | 'bottom' 
  }>>(new Map());
  const controlsRef = useRef<OrbitControls>();

  const getColorForScore = (score: number): THREE.Color => {
    const t = score / 100;
    
    // Create colors that match our CSS colors but for THREE.js
    const colors = {
      red: new THREE.Color(255/255, 89/255, 89/255),
      orange: new THREE.Color(255/255, 170/255, 85/255),
      green: new THREE.Color(92/255, 184/255, 92/255)
    };
    
    let finalColor;
    if (t <= 0.5) {
      // Interpolate between red and orange
      finalColor = colors.red.lerp(colors.orange, t * 2);
    } else {
      // Interpolate between orange and green
      finalColor = colors.orange.lerp(colors.green, (t - 0.5) * 2);
    }
    
    // Add some transparency by reducing intensity
    return finalColor.multiplyScalar(0.9);
  };

  const [regions] = useState<BrainRegion[]>([
    {
      name: "Hypothalamus",
      score: 11,
      center: new THREE.Vector3()
    },
    {
      name: "Cervical Spine",
      score: 16,
      center: new THREE.Vector3()
    },
    {
      name: "Cerebellum",
      score: 56,
      center: new THREE.Vector3()
    },
    {
      name: "Occipital Lobe",
      score: 34,
      center: new THREE.Vector3()
    },
    {
      name: "Brainstem",
      score: 97,
      center: new THREE.Vector3()
    },
    {
      name: "Limbic System",
      score: 95,
      center: new THREE.Vector3()
    },
    {
      name: "Vestibular Nuclei",
      score: 100,
      center: new THREE.Vector3()
    }
  ]);

  const updateCameraForScreenSize = useCallback(() => {
    if (!cameraRef.current || !mountRef.current) return;

    const isMobile = window.innerWidth <= MOBILE_BREAKPOINT;
    
    // Update camera position
    const targetPosition = isMobile ? MOBILE_CAMERA_POSITION : DESKTOP_CAMERA_POSITION;
    cameraRef.current.position.copy(targetPosition);
    
    // Update FOV
    cameraRef.current.fov = isMobile ? MOBILE_FOV : DESKTOP_FOV;
    cameraRef.current.updateProjectionMatrix();
    
    // Update base camera position for mouse movement
    CAMERA_BASE_POSITION.copy(targetPosition);
    
    // Update renderer size
    if (rendererRef.current) {
      rendererRef.current.setSize(mountRef.current.clientWidth, mountRef.current.clientHeight);
    }
  }, []);

  const handleResize = useCallback(() => {
    if (!overlayRef.current || !mountRef.current) return;
    
    // Update camera and renderer for screen size
    updateCameraForScreenSize();
    
    // Get the device pixel ratio
    const pixelRatio = Math.min(window.devicePixelRatio, 2);
    
    // Set the canvas size in pixels (scaled up for retina)
    overlayRef.current.width = mountRef.current.clientWidth * pixelRatio;
    overlayRef.current.height = mountRef.current.clientHeight * pixelRatio;
    
    // Set the canvas CSS size (actual display size)
    overlayRef.current.style.width = `${mountRef.current.clientWidth}px`;
    overlayRef.current.style.height = `${mountRef.current.clientHeight}px`;
    
    if (!ctx.current) {
      ctx.current = overlayRef.current.getContext('2d');
    }
    
    // Scale all drawing operations
    if (ctx.current) {
      ctx.current.scale(pixelRatio, pixelRatio);
    }
  }, [updateCameraForScreenSize]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  useEffect(() => {
    if (!mountRef.current) return;

    const container = mountRef.current;
    const scene = new THREE.Scene();
    sceneRef.current = scene;
    
    // Camera setup
    const camera = new THREE.PerspectiveCamera(
      75,
      container.clientWidth / container.clientHeight,
      0.1,
      100
    );
    camera.position.set(0, 0, 1.2);
    cameraRef.current = camera;

    // Initialize camera position based on screen size
    updateCameraForScreenSize();

    // Renderer setup
    const renderer = new THREE.WebGLRenderer({
      alpha: true,
      antialias: window.devicePixelRatio === 1
    });
    renderer.setSize(container.clientWidth, container.clientHeight);
    renderer.setPixelRatio(Math.min(1.5, window.devicePixelRatio));
    container.appendChild(renderer.domElement);
    rendererRef.current = renderer;

    // Add invisible plane for raycasting
    const planeGeometry = new THREE.PlaneGeometry(10, 10);
    const planeMaterial = new THREE.MeshBasicMaterial({ visible: false });
    const plane = new THREE.Mesh(planeGeometry, planeMaterial);
    plane.position.z = 0;
    scene.add(plane);
    planeRef.current = plane;

    // Add a group to handle rotation
    const autoRotationGroup = new THREE.Group();
    scene.add(autoRotationGroup);
    autoRotationGroupRef.current = autoRotationGroup;

    // Mouse move handler
    const onMouseMove = (event: MouseEvent) => {
      lastInteractionTimeRef.current = Date.now();
      
      const rect = container.getBoundingClientRect();
      mouseRef.current.x = ((event.clientX - rect.left) / container.clientWidth) * 2 - 1;
      mouseRef.current.y = -((event.clientY - rect.top) / container.clientHeight) * 2 + 1;

      // Update target camera position based on mouse position
      targetCameraPositionRef.current.set(
        CAMERA_BASE_POSITION.x + mouseRef.current.x * CAMERA_MOVE_RANGE,
        CAMERA_BASE_POSITION.y + mouseRef.current.y * CAMERA_MOVE_RANGE,
        CAMERA_BASE_POSITION.z
      );

      if (raycasterRef.current && planeRef.current && instancedMeshRef.current) {
        raycasterRef.current.setFromCamera(mouseRef.current, camera);
        const intersects = raycasterRef.current.intersectObject(planeRef.current);
        
        if (intersects.length > 0) {
          instancedMeshRef.current.material.uniforms.uPointer.value.copy(intersects[0].point);
        }
      }
    };

    window.addEventListener('mousemove', onMouseMove);

    // Load brain model
    const loader = new GLTFLoader();
    loader.load('/brain.glb', (gltf) => {
      console.log('Brain model loaded');
      const brain = gltf.scene.children[0] as Mesh<BufferGeometry>;
      brainRef.current = brain;
      
      const positions = brain.geometry.attributes.position.array;
      const bounds = analyzeBrainGeometry(positions);

      const geometry = new THREE.BoxGeometry(0.004, 0.004, 0.004, 1, 1, 1);
      const material = new ShaderMaterial({
        vertexShader: `
          uniform vec3 uPointer;
          uniform vec3 uColor;
          uniform float uRotation;
          uniform float uSize;
          uniform float uHover;
          
          varying vec3 vColor;
          
          #define PI 3.14159265359
          
          void main() {
            vec4 mvPosition = vec4(position, 1.0);
            mvPosition = instanceMatrix * mvPosition;
            
            float d = distance(uPointer, mvPosition.xyz);
            float c = smoothstep(0.45, 0.1, d);
            
            float scale = uSize + c*8.*uHover;
            vec3 pos = position * scale;
            
            mvPosition = instanceMatrix * vec4(pos, 1.0);
            gl_Position = projectionMatrix * modelViewMatrix * mvPosition;
            
            vColor = uColor;
          }
        `,
        fragmentShader: `
          varying vec3 vColor;
          void main() {
            // Check if it's the main brain color (white) by checking if all RGB components are equal and high
            bool isMainColor = vColor.r > 0.9 && vColor.g > 0.9 && vColor.b > 0.9;
            
            // Use 0.1 opacity for main color, 1.0 for regions
            float opacity = isMainColor ? 0.07 : 1.0;
            
            gl_FragColor = vec4(vColor, opacity);
          }
        `,
        wireframe: true,
        transparent: true,
        uniforms: {
          uPointer: { value: new THREE.Vector3() },
          uColor: { value: new THREE.Color() },
          uRotation: { value: 0 },
          uSize: { value: 1 },
          uHover: { value: 1 }
        }
      });

      const instancedMesh = new InstancedUniformsMesh(geometry, material, positions.length / 3);
      
      // Calculate hypothalamus position first so we can reference it
      const hypothalamusCenter = new THREE.Vector3(
        bounds.center.x,
        bounds.center.y - (bounds.max.y - bounds.min.y) * 0.15, // Positioned relative to brain center
        bounds.center.z
      );

      // Function to check if a point is within the hypothalamus region
      const isHypothalamus = (point: THREE.Vector3): boolean => {
        const distance = point.distanceTo(hypothalamusCenter);
        const hypothalamusRadius = (bounds.max.y - bounds.min.y) * 0.04;
        return distance < hypothalamusRadius;
      };

      // Function to check if a point is in the cervical spine region
      const isCervicalSpine = (point: THREE.Vector3, bounds: BrainBounds): boolean => {
        const normalizedHeight = (point.y - bounds.min.y) / (bounds.max.y - bounds.min.y);
        return normalizedHeight < 0.05; // Bottom 5% of the model
      };

      // Function to check if a point is in the cerebellum region
      const isCerebellum = (point: THREE.Vector3, bounds: BrainBounds): boolean => {
        // Define the center of the cerebellum "ball"
        const cerebellumCenter = new THREE.Vector3(
          0.4, // Back of head
          -0.3, // Lower part
          0.0  // Center (left-right)
        );
        
        // Check if point is within a sphere
        const distance = point.distanceTo(cerebellumCenter);
        return distance < 0.25; // Adjust radius as needed
      };

      // Add occipital lobe detection
      const isOccipitalLobe = (point: THREE.Vector3, bounds: BrainBounds): boolean => {
        // Define the region just above the cerebellum at the back
        const isBackOfBrain = point.x > 0.2; // Back portion
        const isAboveCerebellum = point.y > -0.1 && point.y < 0.2; // Above cerebellum but not too high
        const isNearMidline = Math.abs(point.z) < 0.2; // Centered, but can extend to sides
        
        return isBackOfBrain && isAboveCerebellum && isNearMidline;
      };

      // Add brainstem detection function
      const isBrainstem = (point: THREE.Vector3, bounds: BrainBounds): boolean => {
        // Create a vertical column from hypothalamus down
        const distanceFromMidline = Math.sqrt(
          Math.pow(point.z, 2) + // Distance from center in z-axis
          Math.pow(point.x - 0.2, 2) // Distance from slightly back position
        );
        
        // Check if point is:
        // 1. Within a cylinder shape
        // 2. Below hypothalamus
        // 3. Above or at cervical region
        return (
          distanceFromMidline < 0.2 && // Width of the column
          point.y < hypothalamusCenter.y && // Start below hypothalamus
          point.y > -0.7 // Go down to cervical region
        );
      };

      // Add limbic system detection
      const isLimbicSystem = (point: THREE.Vector3, bounds: BrainBounds): boolean => {
        // Move the reference point higher up
        const heightOffset = 0.3; // Increased from 0.3 to 0.4
        
        // Get position relative to raised reference point
        const relativeX = point.x - hypothalamusCenter.x;
        const relativeY = point.y - (hypothalamusCenter.y + heightOffset); // Moved higher
        const relativeZ = point.z - hypothalamusCenter.z;
        
        // Calculate distance from C-shaped curve
        const radius = 0.3; // Radius of the C shape
        const distanceFromCurve = Math.abs(
          Math.sqrt(relativeX * relativeX + relativeY * relativeY) - radius
        );
        
        return (
          distanceFromCurve < 0.05 && // Thickness of the limbic structures
          point.y > hypothalamusCenter.y + 0.1 && // Higher starting point (increased from 0.2)
          point.y < hypothalamusCenter.y + 0.6 && // Higher ending point (increased from 0.5)
          Math.abs(point.z) < 0.2 // Within reasonable lateral bounds
        );
      };

      // Add vestibular nuclei detection
      const isVestibularNuclei = (point: THREE.Vector3): boolean => {
        // Position it just below and behind hypothalamus, in the brainstem area
        const vestibularCenter = new THREE.Vector3(
          hypothalamusCenter.x + 0.1, // Slightly posterior to hypothalamus
          hypothalamusCenter.y - 0.15, // Below hypothalamus
          hypothalamusCenter.z // Same lateral position
        );
        
        // Make it a very small region
        const distance = point.distanceTo(vestibularCenter);
        return distance < 0.03; // Very small radius
      };

      // Inside the GLTFLoader callback, where we're setting up instances:
      const regionPoints: { [key: string]: THREE.Vector3[] } = {
        "Hypothalamus": [],
        "Cervical Spine": [],
        "Cerebellum": [],
        "Occipital Lobe": [],
        "Brainstem": [],
        "Limbic System": [],
        "Vestibular Nuclei": []
      };

      // Create a temporary array to store original positions
      const originalPositions: THREE.Vector3[] = [];
      const scatteredPositions: THREE.Vector3[] = [];
      
      // Set up instances with scattered initial positions
      const dummy = new THREE.Object3D();
      for (let i = 0; i < positions.length; i += 3) {
        const originalPos = new THREE.Vector3(
          positions[i],
          positions[i + 1],
          positions[i + 2]
        );
        originalPositions.push(originalPos.clone());
        
        // Create a scattered position
        const scatteredPos = originalPos.clone();
        scatteredPos.add(new THREE.Vector3(
          (Math.random() - 0.5) * SCATTER_RANGE,
          (Math.random() - 0.5) * SCATTER_RANGE,
          (Math.random() - 0.5) * SCATTER_RANGE
        ));
        scatteredPositions.push(scatteredPos);
        
        // Set initial scattered position
        dummy.position.copy(scatteredPos);
        dummy.updateMatrix();
        instancedMesh.setMatrixAt(i / 3, dummy.matrix);
        instancedMesh.setUniformAt('uRotation', i / 3, THREE.MathUtils.randFloat(-1, 1));
        instancedMesh.setUniformAt('uSize', i / 3, THREE.MathUtils.randFloat(0.3, 3));
        
        // Debug which region we're in
        let regionName = "none";
        if (isHypothalamus(originalPos)) regionName = "hypothalamus";
        else if (isCervicalSpine(originalPos, bounds)) regionName = "cervical spine";
        else if (isCerebellum(originalPos, bounds)) regionName = "cerebellum";
        else if (isOccipitalLobe(originalPos, bounds)) regionName = "occipital lobe";
        else if (isBrainstem(originalPos, bounds)) regionName = "brainstem";
        else if (isLimbicSystem(originalPos, bounds)) regionName = "limbic system";
        else if (isVestibularNuclei(originalPos)) regionName = "vestibular nuclei";
        
        console.log('Setting color for region:', regionName);
        
        // Set color based on region
        let color = new THREE.Color(0xFFFFFF); // default transparent white
        if (regionName !== "none") {
          console.log('Checking results for region:', regionName);
          const matchingResult = results.find(r => 
            r.brain_regions.some(br => br.toLowerCase() === regionName)
          );
          if (matchingResult) {
            console.log('Found matching result:', matchingResult);
            color = getColorForScore(matchingResult.score);
          }
        }
        
        instancedMesh.setUniformAt('uColor', i / 3, color);
      }

      // Add the mesh to the scene
      autoRotationGroup.add(instancedMesh);
      instancedMesh.instanceMatrix.needsUpdate = true;

      // Animate the points coming together
      const totalPoints = positions.length / 3;
      for (let i = 0; i < totalPoints; i++) {
        gsap.to(scatteredPositions[i], {
          x: originalPositions[i].x,
          y: originalPositions[i].y,
          z: originalPositions[i].z,
          duration: ANIMATION_DURATION,
          ease: "power2.out",
          delay: Math.random() * 0.5, // Stagger the animations
          onUpdate: () => {
            dummy.position.copy(scatteredPositions[i]);
            dummy.updateMatrix();
            instancedMesh.setMatrixAt(i, dummy.matrix);
            instancedMesh.instanceMatrix.needsUpdate = true;
          }
        });
      }

      // Add model loaded class after animation
      setTimeout(() => {
        document.documentElement.classList.add('model-loaded');
      }, ANIMATION_DURATION * 1000);

      // After calculating bounds, add this log:
      console.log('Brain bounds:', {
        center: bounds.center,
        min: bounds.min,
        max: bounds.max,
        width: bounds.max.x - bounds.min.x,
        height: bounds.max.y - bounds.min.y,
        depth: bounds.max.z - bounds.min.z
      });

      // Calculate center points for each region
      regions.forEach(region => {
        const points = regionPoints[region.name];
        if (points && points.length > 0) {
          // Calculate the centroid of all points in the region
          const centroid = points.reduce((acc, point) => acc.add(point), new THREE.Vector3())
            .divideScalar(points.length);
          
          // Find the point closest to the centroid
          let closestPoint = points[0];
          let minDistance = closestPoint.distanceTo(centroid);
          
          points.forEach(point => {
            const distance = point.distanceTo(centroid);
            if (distance < minDistance) {
              minDistance = distance;
              closestPoint = point;
            }
          });
          
          // Use the closest actual point as the region center
          region.center.copy(closestPoint);
        }
      });
    });

    // Add orbit controls
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true; // Smooth camera movements
    controls.dampingFactor = 0.05;
    controls.minDistance = 0.5; // Minimum zoom distance
    controls.maxDistance = 3; // Maximum zoom distance
    controls.enablePan = false; // Disable panning
    controlsRef.current = controls;

    // Animation loop
    const animate = () => {
      requestAnimationFrame(animate);

      // Update controls
      if (controlsRef.current) {
        controlsRef.current.update();
      }

      // Update label positions
      drawLabels();

      renderer.render(scene, camera);
    };
    animate();

    // Cleanup
    return () => {
      window.removeEventListener('mousemove', onMouseMove);
      renderer.dispose();
      if (controlsRef.current) {
        controlsRef.current.dispose();
      }
      container.removeChild(renderer.domElement);
    };
  }, []);

  // Helper function to get color index for a region
  const getColorForRegion = (brainRegions: string | string[]): THREE.Color => {
    // Default transparent white color
    const defaultColor = new THREE.Color(0xFFFFFF).multiplyScalar(0.1);
    
    // Debug logs
    console.log('Checking region:', brainRegions);
    console.log('Available results:', results);
    
    // If no results data yet, return default color
    if (!results?.length) {
      console.log('No results data, returning default color');
      return defaultColor;
    }

    // Convert region name(s) to lowercase for case-insensitive comparison
    const regionNames = (Array.isArray(brainRegions) ? brainRegions : [brainRegions])
      .map(r => r.toLowerCase());
    
    console.log('Looking for regions:', regionNames);
    
    // Find results where brain_regions exactly match our region names
    const matchingResults = results.filter(r => {
      const hasMatch = r.brain_regions.some(br => regionNames.includes(br.toLowerCase()));
      console.log('Checking result:', r.category_id, 'brain_regions:', r.brain_regions, 'hasMatch:', hasMatch);
      return hasMatch;
    });

    // If no matches found, return default transparent white
    if (!matchingResults.length) {
      console.log('No matching results, returning default color');
      return defaultColor;
    }

    console.log('Found matching results:', matchingResults);
    
    // If there's an active category, only show those regions
    if (activeCategory) {
      const categoryResult = matchingResults.find(r => r.category_id === activeCategory);
      if (!categoryResult) {
        return defaultColor;
      }
      return getColorForScore(categoryResult.score);
    }

    // Get the lowest score among matching results
    const lowestScore = Math.min(...matchingResults.map(r => r.score));
    return getColorForScore(lowestScore);
  };

  const drawLabels = useCallback(() => {
    if (!ctx.current || !cameraRef.current || !overlayRef.current) return;
    
    // Check if mobile and return early if so
    if (window.innerWidth <= MOBILE_BREAKPOINT) {
      // Clear any existing drawings
      ctx.current.clearRect(0, 0, overlayRef.current.width, overlayRef.current.height);
      return;
    }
    
    const pixelRatio = Math.min(window.devicePixelRatio, 2);
    const displayWidth = overlayRef.current.width / pixelRatio;
    const displayHeight = overlayRef.current.height / pixelRatio;
    
    // Reset transform and clear
    ctx.current.setTransform(1, 0, 0, 1, 0, 0);
    ctx.current.clearRect(0, 0, overlayRef.current.width, overlayRef.current.height);
    ctx.current.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    
    // Style setup
    ctx.current!.strokeStyle = '#ffffff';
    ctx.current!.lineWidth = 1;
    ctx.current!.font = '100 13px "Roboto Mono", "JetBrains Mono", "Courier New", monospace';
    ctx.current!.letterSpacing = '0.05em';
    ctx.current!.shadowColor = 'rgba(0, 0, 0, 0.5)';
    ctx.current!.shadowBlur = 2;
    ctx.current!.shadowOffsetX = 1;
    ctx.current!.shadowOffsetY = 1;
    
    // Project all points
    const projectedPoints = regions.map(region => {
      const vector = region.center.clone();
      vector.project(cameraRef.current!);
      
      return {
        region,
        x: (vector.x * 0.5 + 0.5) * displayWidth,
        y: (-vector.y * 0.5 + 0.5) * displayHeight,
        screenY: (-vector.y * 0.5 + 0.5) * displayHeight,
        z: vector.z
      };
    });
    
    // Sort points by vertical position
    projectedPoints.sort((a, b) => a.screenY - b.screenY);
    
    const margin = displayWidth * 0.25;
    const labelSpacing = 30;
    
    // Desktop layout (left/right)
    projectedPoints.forEach((point, index) => {
      const side = index % 2 === 0 ? 'left' : 'right';
      const x = side === 'left' ? margin : displayWidth - margin;
      const y = margin + (index * labelSpacing);
      
      labelPositionsRef.current.set(point.region.name, {
        x,
        y,
        side
      });
    });
    
    // Draw the labels and lines
    projectedPoints.forEach(point => {
      const pos = labelPositionsRef.current.get(point.region.name);
      if (!pos) return;
      
      const color = getColorForRegion(point.region.name);
      const colorStyle = `rgb(${color.r * 255}, ${color.g * 255}, ${color.b * 255})`;
      
      const label = `${point.region.name.toUpperCase()}: ${point.region.score}%`;
      const metrics = ctx.current!.measureText(label);
      const labelWidth = metrics.width;
      
      // Draw connecting line
      ctx.current!.strokeStyle = colorStyle;
      ctx.current!.beginPath();
      ctx.current!.moveTo(point.x, point.y);
      
      // Always use straight lines
      ctx.current!.lineTo(pos.x, pos.y);
      ctx.current!.stroke();
      
      // Draw label text
      ctx.current!.fillStyle = colorStyle;
      const textX = pos.side === 'left' ? pos.x - labelWidth - 8 : pos.x + 8;
      
      // Draw text
      ctx.current!.fillText(label, textX, pos.y);
      
      // Draw underline
      const underlineY = pos.y + 3;
      ctx.current!.beginPath();
      ctx.current!.moveTo(textX, underlineY);
      ctx.current!.lineTo(textX + labelWidth, underlineY);
      ctx.current!.stroke();
    });
  }, [regions]);

  return (
    <div ref={mountRef} style={{ width: '100%', height: '100vh', position: 'relative' }}>
      <canvas
        ref={overlayRef}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          pointerEvents: 'none',
          width: '100%',
          height: '100%'
        }}
      />
    </div>
  );
};

export default ThreeScene; 