import React, { useState, useEffect } from 'react';
import styles from './SplashScreen.module.css';

const SplashScreen: React.FC = () => {
  const [isRevealing, setIsRevealing] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsRevealing(true);
    }, 1);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={styles['splash-container']}>
      <div className={`${styles['splash-text']} ${isRevealing ? styles['reveal'] : ''}`}>
        headquarters
      </div>
    </div>
  );
};

export default SplashScreen;
