import { registerBlockType } from "@quillforms/blocks";
import display from "./display";

registerBlockType("custom-opinion-block", {
  supports: {
    editable: true
  },
  attributes: {
    start: {
      type: "number",
      default: 0
    },
    end: {
      type: "number",
      default: 6
    }
  },
  display
});
