import React, { useState, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Cloudinary } from '@cloudinary/url-gen';
import { AdvancedVideo } from '@cloudinary/react';
import { FaCheck, FaBan, FaChevronLeft } from 'react-icons/fa';
import { RiVoiceprintFill } from "react-icons/ri";
import styles from './VideoViewPage.module.css';

interface VideoViewPageProps {
  onAction: (
    action: 'completed' | 'incomplete',
    content: { comments?: string; video?: string } | {},
    patientId: string,
    rehabId: string
  ) => void;
}

const VideoViewPage: React.FC<VideoViewPageProps> = ({ onAction }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { videoUrl, exerciseId, patientId, description, content } = location.state || {};
  const [isPlaying, setIsPlaying] = useState(true);
  const [comments, setComments] = useState(
    content?.comments || ''
  );
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);

  // Initialize Cloudinary instance
  const cld = new Cloudinary({
    cloud: {
      cloudName: 'dwto4kslu', // Replace with your Cloudinary cloud name
    },
  });

  // Create a Cloudinary video object
  const video = cld.video(videoUrl); // Use the videoUrl or public ID

  const handleVideoClick = () => {
    setIsPlaying(!isPlaying);
  };

  const handleComplete = async () => {
    try {
      onAction(
        'completed',
        {
          ...(comments ? { comments } : {}),
          video: videoUrl
        },
        patientId,
        exerciseId
      );
      navigate(-1);
    } catch (error) {
      console.error('Error completing exercise:', error);
    }
  };

  const handleIncomplete = async () => {
    try {
      onAction(
        'incomplete',
        {
          ...(comments ? { comments } : {}),
          video: videoUrl
        },
        patientId,
        exerciseId
      );
      navigate(-1);
    } catch (error) {
      console.error('Error marking exercise as incomplete:', error);
    }
  };

  const handlePhoneCall = () => {
    window.location.href = 'tel:+16282774192';
  };

  const videoComponent = useMemo(() => (
    <AdvancedVideo
      cldVid={video}
      autoPlay
      loop
      // muted
      controls={false}
      onClick={handleVideoClick}
      className={styles.video}
      playsInline
      webkit-playsinline="true"
    />
  ), [videoUrl]); // Only re-render when videoUrl changes

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <button onClick={() => navigate(-1)} className={styles.backButton}>
          <FaChevronLeft />
          <span>Home</span>
        </button>
        <button onClick={handlePhoneCall} className={styles.phoneButton}>
          <RiVoiceprintFill />
        </button>
      </div>

      {videoComponent}
      
      <div className={`${styles.overlay} ${isDescriptionExpanded ? styles.expanded : ''}`}>
        <div className={styles.commentSection}>
          <div 
            className={`${styles.description} ${isDescriptionExpanded ? styles.expanded : ''}`}
            onClick={() => setIsDescriptionExpanded(!isDescriptionExpanded)}
          >
            {description}
          </div>
          <textarea
            value={comments}
            onChange={(e) => setComments(e.target.value)}
            placeholder="Add a comment..."
            className={styles.commentInput}
          />
        </div>
        
        <div className={styles.actionButtons}>
          <button 
            onClick={handleIncomplete}
            className={`${styles.actionButton} ${styles.incomplete}`}
          >
            <FaBan />
          </button>
          <button 
            onClick={handleComplete}
            className={`${styles.actionButton} ${styles.complete}`}
          >
            <FaCheck />
          </button>
        </div>
      </div>
    </div>
  );
};

export default VideoViewPage; 